/* eslint-disable react/display-name */

import React, { createRef, Fragment, useEffect, useState } from "react"
import { Badge, Button, CardLink, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { ColumnDescription } from "react-bootstrap-table-next"
import { Link, useHistory } from "react-router-dom"
import { RouteNames } from "../../routes"
import { FilterOption, SearchOption, TabOption } from "../../components/table/TableFilter"
import { ActionOption, RemoteTableSelectionData } from "../../components/table/SelectedRowAction"
import RemoteTable, { createDefaultSortOptions, defaultPageInfo } from "../../components/table/RemoteTable"

import {
  EditableFulfillStatuses,
  EditableFulfillStatusName,
  FulfillDesignStatus,
  FulfillDesignStatusColor,
  FulfillDesignStatusName,
  FulfillDesignStatusValues,
  FulfillStatus,
  FulfillStatusColor,
  FulfillStatusIssue,
  FulfillStatusIssueColor,
  FulfillStatusIssueName,
  FulfillStatusIssueValues,
  FulfillStatusName,
  FulfillStatusValues,
} from "../../types/fulfill-status.type";
import { format } from "date-fns"
import { FulfillOrderDto, FulfillOrderServices } from "../../data/services/fulfill-order.service"
import {
  bulkEditFulfillOrderAtom,
  bulkEditFulfillOrderSelector,
  bulkEditFulfillOrderState,
  fulfillOrderQueryState,
  fulfillOrderSelector,
  fulfillOrderStatusCountSelector
} from "../../data/atoms/fulfill-order.atom";
import { t } from "../../core/translations";
import Breadcrumb from "../../components/layout/Breadcrumb";
import { FulfillOrderActions } from "../../data/actions/fulfill-order.action";
import { Toasts } from "../../core/notification/notifications";
import { Messages } from "../../common/messages";
import { BulkUpdateSelectPopup } from "../../components/modal/bulk-update-popup";
import { ImportModal, ImportType } from "./components/import-modal";
import { RecoilState, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { portableModalState } from "../../data/atoms/app.atom";
import { FulfillmentOrderBulkEditor } from "./components/fulfill-order-bulk-edit";
import { BulkEditorView } from "../../components/bulk/bulk-editor.type";
import DropdownComponent from "../../components/dropdown/DropdownComponent";
import { DefaultBulkEditAction } from "../../components/bulk/BulkEditor";
import { Labels } from "../../common/labels";
import { ExportType } from "../../types/export.type";
import { allSaleChannelsSelector } from "../../data/atoms/sale-channel.atom";
import { FulfillServiceSelect } from "./components/fulfill-service.select";
import { Filter, QueryBuilder, QueryFilter } from "../../core/rest-api/query-builder";
import {  ExportDesignPopup, ModalExportFulfillOrder } from "./components/fultill-orders.export-popup";
import { TooltipComponent } from "components/common/TooltipComponent";
import { CustomType, CustomTypeOptions } from "./types/custom-type.type";
import { BiErrorCircle, BiLinkExternal } from "react-icons/bi";
import { MdImageNotSupported, MdRemoveCircle } from "react-icons/md";
import { ModalButtons, ModalContent } from "components/modal/portable-modal";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { Strings } from "../../utils/strings";
import { Loading } from "../../components/common/Loading";
import { BsCheckCircle, BsFillClockFill, BsPatchCheck } from "react-icons/bs";
import { PersonalCustomBulkEdit, ReviewCustomBulkEditAction } from "./components/personal-custom-bulk-edit";
import { createDocumentPageName } from "../../utils/utils";
import { ImWarning } from "react-icons/im"
import { BulkChangeFulfillStatusSelectPopup } from "./components/fullfill-orders-action-fulfill-status";
import FulfillmentOrderResult from "./components/fulfillment-order-result";
import { ExportModal, exportRadioDataTypeValues, exportRadioDataValues } from "./components/export-modal";
import Select from "react-select";
import { VendorSelect } from "pages/products/components/vendor.select";
import { BulkUpdateVendorSelectPopup } from "./components/bulk-update-vendor";
import { FulfillmentOrderBulkEditorActions, FulfillmentOrderBulkEditorRef } from "./components/fulfillment-order-bulk-edit";
import { FulfillmentOrderEditFulfillVariantActions, FulfillmentOrderEditVariantBulkEditor } from "./components/fulfillment-order-edit-variant";
import { AccountDto, AccountSelect } from "./components/account.select";
import { BulkUpdateVendorPopup } from "./components/popup-bulk-update-vendor";
import AsyncSelect from "react-select/async";
import { AccountActions } from "data/actions/account.action";
import { ActionUploadDesign, FulfillItemBulkUpload } from "./components/fulfill-item-upload-design";
import { createLinkEmbedParam, createUrlEmbed } from "utils/embed";
import { BulkActionClonePopup } from "./components/popup-bulk-clone"



export interface BulkEditingType {
  editor: BulkEditorView
  data: any[],
  loading?: boolean
}

export const FulfillOrdersPage = () => {
  let history = useHistory()
  const setPortableModal = useSetRecoilState(portableModalState)
  const [importType, setImportType] = useState<ImportType>(ImportType.NULL)
  const [bulkEditorState, setBulkEditorState] = useState<BulkEditingType | any>()
  const [bulkEditSelector, setBulkEditSelector] = useState<RecoilState<any>>(bulkEditFulfillOrderAtom(BulkEditorView.FULFILL_ORDER))
  const [selectedIds, setSelectedIds] = useState<any[]>([])
  const [saleChannels, setSaleChannels] = useState([])
  const saleChannelLoadable = useRecoilValueLoadable(allSaleChannelsSelector)
  const countByStatusLoadable = useRecoilValueLoadable(fulfillOrderStatusCountSelector)
  const [countByStatus, setCountByStatus] = useState<any>({})
  const [queryState, setQueryState] = useRecoilState(fulfillOrderQueryState(defaultPageInfo))
  const loadable = useRecoilValueLoadable(fulfillOrderSelector)

  const [bulkEditFulfillOrderIds, setBulkEditFulfillOrderIds] = useRecoilState(bulkEditFulfillOrderState([]))
  const bulkEditFulfillOrderLoadable = useRecoilValueLoadable(bulkEditFulfillOrderSelector(bulkEditFulfillOrderIds))
  const [exportType, setExportType] = useState<ExportType>(ExportType.NULL)
  // const [exportDesignType, setExportDesignType] = useState('')
  const [totalItems, setTotalItems] = useState<number>()
  const fulfillmentOrderEditorRef = createRef<FulfillmentOrderBulkEditorRef>()

  let searchParams = Object.fromEntries(new URLSearchParams(location.search))

  useEffect(() => {
    if(bulkEditFulfillOrderLoadable.state == 'hasError') {
      setBulkEditorState((preState: any) => ({}))
    } else {
      setBulkEditorState((preState: any) => ({
        ...preState,
        data: bulkEditFulfillOrderLoadable.contents?.pageItems || [],
        loading: bulkEditFulfillOrderLoadable.contents instanceof Promise
      }))
    }
  }, [bulkEditFulfillOrderLoadable.contents])
  useEffect(() => {
    if (loadable.state == 'hasValue') {
      setTotalItems(loadable.contents?.pageInfo?.totalCount)
    }
  }, [loadable.state])
  const startBulkEdit = (editor: BulkEditorView, ids: string[]) => {
    setBulkEditorState({ editor, data: [], loading: true })
    setBulkEditFulfillOrderIds({ ids, atomic: Date.now(), editor })
  }

  const refreshData = () => {
    setQueryState(prev => ({ ...prev }))
  }

  interface DataTypeExportDesign {
    outputFolder: string
    configPathTemplate: string
    numberOfOption: number
  }

  useEffect(() => { document.title = createDocumentPageName(t(Labels.order_items)) }, [])

  useEffect(() => {
    if (saleChannelLoadable.state == "hasValue") {
      setSaleChannels(saleChannelLoadable.contents || [])
    }
  }, [saleChannelLoadable.state])

  useEffect(() => {
    if (countByStatusLoadable.state == "hasValue") {
      setCountByStatus(countByStatusLoadable.contents || {})
    }
  }, [countByStatusLoadable.state])

  useEffect(() => setBulkEditSelector(bulkEditFulfillOrderAtom(bulkEditorState?.editor)), [bulkEditorState])

  const getFulfillDesignIssue = (row: any) => {
    return row.fulfillDesignIssue || ""
  }

  let columns: ColumnDescription<FulfillOrderDto>[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { minWidth: 80, width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.ORDER_ITEMS}/${row.id}`)}>
              {`${cell}`}
            </Link>
          </>
        )
      },
      hidden: true
    },
    {
      dataField: "orderNumber",
      text: t(Labels.order),
      headerStyle: { minWidth: 280 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        const orderId = Number(row.orderId || row.order?.id || '')
        return <Fragment>
          <Col>
            <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.ORDER_ITEMS}/${row.id}`)}>
              {`${cell}`}
            </Link>
            <p className="mb-0">{row.variationTitle}</p>
            {/*{*/}
            {/*  isNaN(orderId) ? null :*/}
            {/*    <Row>*/}
            {/*      <Col xs={"auto"} className="pe-0">*/}
            {/*        <TooltipComponent tooltip={t(Labels.order_id)}>*/}
            {/*          { orderId }*/}
            {/*        </TooltipComponent>*/}
            {/*      </Col>*/}
            {/*      <Col className="ps-1">*/}
            {/*        <Link target='_blank' style={{ fontWeight: 500 }} to={`${RouteNames.ORDERS}/${orderId}`}>*/}
            {/*          <BiLinkExternal size={16}/>*/}
            {/*        </Link>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*}*/}
          </Col>
        </Fragment>;
      },
    },
    {
      dataField: "productName",
      text: t(Labels.product_name),
      headerStyle: { minWidth: 380 },
      hidden: true,
    },
    {
      dataField: "fulfillVariant",
      text: t(Labels.fulfill_variant),
      headerStyle: { minWidth: 280 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <Fragment>
            <Col className="d-flex align-items-center">
              {row.fulfillVariant?.fulfillServiceId == row.fulfillService?.id && row.fulfillService?.name ? (
                <Badge color={"success"} pill className="me-1">
                  {row.fulfillService?.name}
                </Badge>
              ) : null}
              {row.fulfillmentOrderId ? (
                <a target="_blank" rel="noreferrer" href={createUrlEmbed(RouteNames.FULFILLMENT_ORDERS_DETAIL.replace(":id", row.fulfillmentOrderId))} className="d-flex" >
                  <Badge color={"light"} pill className="me-1">
                    {`Fulfillment Order: ${row.fulfillmentOrderId}`} 
                  
                    <BiLinkExternal className="ms-1"/>
                  </Badge>
                </a>
              ) : (
                <></>
              )}
            </Col>
            <p className="mb-0">{row.fulfillVariant?.variantTitle || ""}</p>
          </Fragment>
        )
      },
    },
    {
      dataField: "sku",
      text: t(Labels.design_sku),
      headerStyle: { minWidth: 160, width: 200 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <Col>
            <Row className="m-0">{row.sku}</Row>
            <div className="m-0 d-flex flex-row gap-2">
              <div style={{paddingTop: '1px'}}>{row.artworkSku}</div>
              <TooltipComponent tooltip={`${t(Labels.artwork)}: ${row.artworkSku || ''}`} className="text-center">
                <BsCheckCircle color="success" size={16}/>
              </TooltipComponent>
            </div>
          </Col>
        )
      },
    },
    {
      dataField: "vendor",
      text: t(Labels.vendor),
      headerStyle: { minWidth: 100 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <Col>
            <Row className="m-0">{cell?.name}</Row>
            <Row className="m-0">{cell?.username}</Row>
          </Col>
        )
      },
    },
    {
      dataField: "status",
      text: t(Labels.status),
      headerStyle: { width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        const toolTipStatusMessage =  row.statusMessages?.join(", ")
        const toolTipStatus = `${Labels.fulfill_status}: ${FulfillStatusName[row.status]}` 
        const toolTipStatusIssue =`${Labels.fulfill_status_issue}: ${FulfillStatusIssueName[row.statusIssue] || row.statusIssue}`
        
        return (
          <Col
            style={{
              marginTop: -10,
              marginBottom: -10,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "5px",
            }}
          >
            
            <TooltipComponent tooltip={toolTipStatus}>
            <Badge color={FulfillStatusColor[row.status] || "light"} pill>
                {FulfillStatusName[row.status]}
              </Badge>
              </TooltipComponent>
            <Col className="d-flex">
              
              <TooltipComponent className={(row.statusIssue === FulfillStatusIssue.WARNING) ? 'me-1' : ""} tooltip={toolTipStatusIssue}>
                <Badge color={FulfillStatusIssueColor[row.statusIssue] || "light"} pill>
                  {FulfillStatusIssueName[row.statusIssue] || row.statusIssue}
                </Badge>
              </TooltipComponent>
              <TooltipComponent tooltip={toolTipStatusMessage}>
                {row.statusIssue === FulfillStatusIssue.WARNING ? <ImWarning color="orange" fontSize={14} /> : <></>}
              </TooltipComponent>
            </Col>
          </Col>
        )
      },
    },
    {
      dataField: "messages",
      text: t(Labels.message),
      headerStyle: { minWidth: 150 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <>
            { row?.statusMessages?.map((item: any, idx: number) => <span className="d-block" key={idx}>{item}</span>) }
            { row?.fulfillMessages?.map((item: any, idx: number) => <span className="d-block" key={idx}>{item}</span>) }
          </>
        )
      },
    },

    {
      dataField: "order", // TODO check using key shippingAddress not show any thing
      text: t(Labels.customer),
      hidden: true,
      headerStyle: { minWidth: 380 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        let billingAddress = Strings.getAddress(cell.billingAddress)
        let shippingAddress = Strings.getAddress(cell.shippingAddress)
        return (
          <Fragment>
            <Row className="mb-0">
              <h6 className="me-2">
                {cell?.billingAddress?.firstName} {cell?.billingAddress?.lastName}
              </h6>
            </Row>
            {/* <Row className="mb-0">
              <p className="mb-0">{Strings.getAddress(cell.billingAddress)} ({t(Labels.billing)})</p>
            </Row>
            <Row className="mb-0">
              <p className="mb-0">{Strings.getAddress(cell.shippingAddress)} ({t(Labels.shipping)})</p>
            </Row> */}
            {billingAddress === shippingAddress ? (
              <Row className="mb-0">
                <p className="mb-0">{billingAddress}</p>
              </Row>
            ) : (
              <>
                <Row className="mb-0">
                  <p className="mb-0">
                    {t(Labels.billing)}: {billingAddress}
                  </p>
                </Row>
                <Row className="mb-0">
                  <p className="mb-0">
                    {t(Labels.shipping)}: {shippingAddress}
                  </p>
                </Row>
              </>
            )}
          </Fragment>
        )
      },
    },
    {
      dataField: 'customerNote',
      text: t(Labels.customer_note),
      headerStyle: { minWidth: 130 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <Fragment>
              <p className="mb-0">{t(cell)}</p>
          </Fragment>
        )
      },
    },
    // {
    //   dataField: "productImage",
    //   text: t(Labels.images),
    //   headerStyle: { width: 80 },
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cell, row: any) => {
    //     const mockupUrl = row.mockupUrl || row.artworkUrl
    //     const designUrl = row.designUrl || row.artworkUrl
    //     const missingArtwork = !mockupUrl && !designUrl && !row.artwork
    //     const missingArtworkUrl = !mockupUrl && !designUrl && row.artwork && !row.artwork.url
    //     let fulfillDesignIssue = getFulfillDesignIssue(row);
    //     return <>
    //       {
    //         row.customType == CustomType.PERSONAL_CUSTOM || (fulfillDesignIssue && !row.mockupUrl && !row.artworkUrl) ? (
    //             fulfillDesignIssue ?
    //               <div className='text-danger'>
    //                 <TooltipComponent tooltip={fulfillDesignIssue} className='text-center'>
    //                   <BiErrorCircle size={20}/>
    //                 </TooltipComponent>
    //               </div>
    //               : null
    //           ) :
    //           missingArtwork || missingArtworkUrl ?
    //             <div className='text-danger'>
    //               <TooltipComponent
    //                 className='text-center'
    //                 tooltip={t(missingArtwork ? `${Labels.no_artwork} ${row.artworkSku || ''}` : `${Labels.no_design_image_url_for} ${row.artworkSku}`)} >
    //                 {
    //                   missingArtwork ? <MdRemoveCircle size={20}/> : <MdImageNotSupported size={20}/>
    //                 }
    //               </TooltipComponent>
    //             </div>
    //             :
    //             <div className='d-flex gap-3'>
    //               <a target={"_blank"} rel="noreferrer" href={`${mockupUrl || '#'}`}
    //                  className={mockupUrl ? 'text-success' : 'text-danger'}>
    //                 <TooltipComponent
    //                   tooltip={(row.mockupUrl || row.designUrl ? t(Labels.mockup_url) : t(Labels.artwork_mockup)) + fulfillDesignIssue }>
    //                   <i className={`mdi ${mockupUrl ? 'mdi-cloud-check' : 'mdi-close-box'} font-size-18`}/>
    //                 </TooltipComponent>
    //               </a>
    //               <a target={"_blank"} rel="noreferrer" href={`${designUrl || '#'}`}
    //                  className={designUrl ? 'text-success' : 'text-danger'}>
    //                 <TooltipComponent
    //                   tooltip={(row.mockupUrl || row.designUrl ? t(Labels.design_url) : t(Labels.artwork_url)) + fulfillDesignIssue}>
    //                   <i className={`mdi ${designUrl ? 'mdi-cloud-check' : 'mdi-close-box'} font-size-18`}/>
    //                 </TooltipComponent>
    //               </a>
    //             </div>
    //       }
    //       <div className='d-flex gap-3'>
    //         <a target={"_blank"} rel="noreferrer" href={`${row.fulfillMockup || '#'}`}
    //            className={row.fulfillMockup ? 'text-success' : 'text-danger'}>
    //           <TooltipComponent tooltip={t(Labels.fulfill_mockup)}>
    //             <i className={`mdi ${row.fulfillMockup ? 'mdi-cloud-check' : 'mdi-close-box'} font-size-18`}/>
    //           </TooltipComponent>
    //         </a>
    //         <a target={"_blank"} rel="noreferrer" href={`${row.fulfillDesign || '#'}`}
    //            className={row.fulfillDesign ? 'text-success' : 'text-danger'}>
    //           <TooltipComponent tooltip={t(Labels.fulfill_design)}>
    //             <i className={`mdi ${row.fulfillDesign ? 'mdi-cloud-check' : 'mdi-close-box'} font-size-18`}/>
    //           </TooltipComponent>
    //         </a>
    //       </div>
    //     </>
    //   }
    // },
    // {
    //   dataField: "status",
    //   text: t(Labels.issue_and_design),
    //   headerStyle: { minWidth: 135 },
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cell, row: any) => {
    //     return (
    //       <Col
    //         style={{
    //           marginTop: -10,
    //           marginBottom: -10,
    //           display: "flex",
    //           flexDirection: "column",
    //           alignItems: "flex-start",
    //           gap: "5px",
    //         }}
    //       >
    //         <Badge color={FulfillStatusIssueColor[row.statusIssue] || "light"} pill>
    //           {FulfillStatusIssueName[row.statusIssue] || row.statusIssue}
    //         </Badge>
    //         <TooltipComponent className="d-inline" tooltip={t(row.customReviewed ? Messages.personal_custom_reviewed : Messages.personal_custom_wait_for_review)}>
    //           <div className='d-inline-block'>
    //               { row.customReviewed ? <BsCheckCircle color="green" fontSize={16} /> : <BsFillClockFill color="gray" fontSize={16}/>}
    //             <Badge className="ms-1" color={FulfillDesignStatusColor[row.designStatus] || "light"} pill>
    //               {FulfillDesignStatusName[row.designStatus || FulfillDesignStatus.NO_DESIGN] || row.designStatus}
    //             </Badge>
    //           </div>
    //         </TooltipComponent>
    //       </Col>
    //     )
    //   },
    // },
    {
      dataField: "design",
      text: t(Labels.design),
      headerStyle: { minWidth: 150, width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        const mockupUrl = row.mockupUrl || row.artworkUrl
        const designUrl = row.designUrl || row.artworkUrl
        const missingArtwork = !mockupUrl && !designUrl && !row.artwork
        const missingArtworkUrl = !mockupUrl && !designUrl && row.artwork && !row.artwork.url
        let fulfillDesignIssue = getFulfillDesignIssue(row);
        const designStatusUpload = row.designStatus === FulfillDesignStatus.UPLOADING
        return (
          <Col
            style={{
              marginTop: -10,
              marginBottom: -17,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "3px",
            }}
          >
            <div className="d-inline-block">
              <TooltipComponent className="d-inline" tooltip={t(row.customReviewed ? Messages.personal_custom_reviewed : Messages.personal_custom_wait_for_review)}>
                {row.customReviewed ? <BsCheckCircle color="green" fontSize={16} /> : <BsFillClockFill color="gray" fontSize={16} />}
              </TooltipComponent>
              <Badge className="ms-1" color={FulfillDesignStatusColor[row.designStatus] || "light"} pill>
                {FulfillDesignStatusName[row.designStatus || FulfillDesignStatus.NULL] || row.designStatus}
              </Badge>
            </div>
            <div>
                <div className="d-flex gap-2">
                  {row.customType == CustomType.PERSONAL_CUSTOM || (fulfillDesignIssue && !row.mockupUrl && !row.artworkUrl) ? (
                    <div className="d-flex align-items-center">
                      {
                        fulfillDesignIssue ? (
                          <TooltipComponent className="text-danger text-center" tooltip={fulfillDesignIssue}>
                            <BiErrorCircle size={18} />
                          </TooltipComponent>
                        ) : null
                      }
                    </div>
                  ) : missingArtwork || missingArtworkUrl ? (
                    <div className="text-danger d-flex align-items-center">
                      <TooltipComponent
                        className="text-center"
                        tooltip={t(
                          missingArtwork
                            ? `${Labels.no_artwork} ${row.artworkSku || ""}`
                            : `${Labels.no_design_image_url_for} ${row.artworkSku}`
                        )}
                      >
                        {missingArtwork ? <MdRemoveCircle size={20} /> : <MdImageNotSupported size={20} />}
                        </TooltipComponent>
                    </div>
                  ) : (
                    <>
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        href={`${mockupUrl || "#"}`}
                        className={mockupUrl ? "text-success" : "text-danger"}
                      >
                        <TooltipComponent
                          tooltip={(row.mockupUrl || row.designUrl ? t(Labels.mockup_url) : t(Labels.artwork_mockup)) + fulfillDesignIssue}
                        >
                          <i className={`mdi ${mockupUrl ? "mdi-cloud-check" : "mdi-close-box"} font-size-18`} />
                        </TooltipComponent>
                      </a>
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        href={`${designUrl || "#"}`}
                        className={designUrl ? "text-success" : "text-danger"}
                      >
                        <TooltipComponent
                          tooltip={(row.mockupUrl || row.designUrl ? t(Labels.design_url) : t(Labels.artwork_url)) + fulfillDesignIssue}
                        >
                          <i className={`mdi ${designUrl ? "mdi-cloud-check" : "mdi-close-box"} font-size-18`} />
                        </TooltipComponent>
                      </a>
                    </>
                  )}
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`${row.fulfillMockup || "#"}`}
                    className={designStatusUpload ? 'text-info' : row.fulfillMockup ? "text-success" : "text-danger"}
                  >
                    <TooltipComponent tooltip={t(Labels.fulfill_mockup)}>
                      <i className={`mdi ${designStatusUpload ? 'mdi-cloud-upload' : row.fulfillMockup ? "mdi-cloud-check" : "mdi-close-box" } font-size-18`} />
                    </TooltipComponent>
                  </a>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`${row.fulfillDesign || "#"}`}
                    className={designStatusUpload ? 'text-info' : row.fulfillDesign ? "text-success" : "text-danger"}
                  >
                    <TooltipComponent tooltip={t(Labels.fulfill_design)}>
                      <i className={`mdi ${designStatusUpload ? 'mdi-cloud-upload' : row.fulfillDesign ? "mdi-cloud-check" : "mdi-close-box"} font-size-18`} />
                    </TooltipComponent>
                  </a>
                </div>
            </div>
          </Col>
        )
      },
    },
    {
      dataField: "customType",
      text: t(Labels.custom_type),
      headerStyle: { minWidth: 150 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Col>
         {CustomTypeOptions.find(item => item.value === cell)?.label}
        </Col>
      },
    },
    {
      dataField: "fulfillmentProduct",
      text: t(Labels.total_price),
      headerStyle: { minWidth: 100 },
      //eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Col>
          <Row className="m-0 w-100">{`${row.totalPrice || "0"}`}</Row>
          <Row className="m-0 w-100">{`${row.quantity || "0"} items`}</Row>
        </Col>
      },
    },
    {
      dataField: "vendor",
      text: t(Labels.vendor),
      headerStyle: { minWidth: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <>
            <p className="mb-0">{cell?.name || cell?.username}</p>
          </>
        )
      },
    },
    {
      dataField: "orderCreatedAt",
      text: t(Labels.order_time),
      headerStyle: { minWidth: 110 },
      //eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Col>
          <Row className="m-0 w-100">{format(new Date(cell), "MMM dd, yyyy")}</Row>
          <Row className="m-0 w-100">{format(new Date(cell), "HH:mm")}</Row>
        </Col>
      },
    },
  ]

  let filterOptions: FilterOption[] = [
    {
      name: "channelId",
      label: t(Labels.title_sale_channel),
      type: "checkbox",
      value: saleChannels?.map((channel: any) => ({ value: channel.id, label: channel.name })) || [],
    },
    {
      name: "orderCreatedAt",
      label: t(Labels.order_date),
      type: "date",
      filterBuilder: value => ({ between: value }),
      hidden: true,
    },
    {
      name: "designStatus",
      label: t(Labels.design_status),
      type: "checkbox",
      value: FulfillDesignStatusValues.map(value => ({ value, label: FulfillDesignStatusName[value] })),
      hidden: true,
      filterBuilder: value => {
        let containValues = Array.isArray(value) ? [...value] : [value]
        if(containValues.includes(FulfillDesignStatus.NULL)) {
          containValues.push(null)
        }
        return { contains: containValues }
      },
    },
    {
      name: "status",
      label: t(Labels.fulfill_status),
      type: "checkbox",
      value: FulfillStatusValues.map(value => ({ value, label: FulfillStatusName[value] })),
      hidden: true,
    },
    {
      name: "statusIssue",
      label: t(Labels.fulfill_status_issue),
      type: "checkbox",
      value: FulfillStatusIssueValues.map(value => ({ value, label: FulfillStatusIssueName[value] || value })),
      hidden: true,
    },
    {
      name: "fulfillVariantId",
      label: t(Labels.fulfill_variant),
      type: "radio",
      value: [
        { label: Labels.yes, value: "1" },
        { label: Labels.no, value: "0" },
      ],
      filterBuilder: value => (value == "1" ? { greaterThan: 0 } : { isEmpty: true }),
      hidden: true,
    },
    {
      name: "fulfillServiceId",
      label: t(Labels.fulfill_service),
      type: "custom",
      // eslint-disable-next-line react/display-name
      componentCreator: (filterValue: any, onFilterChange: (name: string, value: any) => void) => (
        <FulfillServiceSelect
          isMulti={true}
          value={filterValue}
          onChange={(value: any) => {
            onFilterChange("fulfillServiceId", value)
          }}
        />
      ),
      filterBuilder: (services: any[]) => {
        return (services?.length ? { contains: services.map(item => item.id) } : undefined)
      },
      hidden: true,
    },
    {
      name: "lineItemCount",
      label: t(Labels.line_item_count),
      type: "radio",
      value: [
        { label: Labels.one_line, value: "1" },
        { label: Labels.multi_lines, value: "any" },
      ],
      filterBuilder: value => ({ [value != "1" ? "greaterThan" : "lessThanEqual"]: 1 }),
      hidden: true,
    },
    {
      name: "sameCustomerCount",
      label: t(Labels.order_same_customer),
      type: "radio",
      value: [
        { label: Labels.one_order, value: "1" },
        { label: Labels.multi_orders, value: "any" },
      ],
      filterBuilder: value => ({ [value != "1" ? "greaterThan" : "lessThanEqual"]: 1 }),
      hidden: true,
    },
    {
      name: "vendorId",
      label: t(Labels.vendor),
      type: "custom",
      filterBuilder: (values: any[]) => {
        const filter = values?.map(item => item.id || null)
        if(filter?.filter(item => !item)?.length){
          filter.push('')
        }
        return { contains: filter }
      },
      componentCreator: (value: any, onFilterChange: any) => {
        const options = async (searchText: string) => {
          const result = await AccountActions.getUserByUsername(searchText)
          return [{username: 'Unknown'}, ...result]
        } 
        return (
          <>
            <AsyncSelect
              isMulti={true}
              isSearchable
              cacheOptions
              defaultOptions={true}
              value={value || null}
              getOptionValue={(option: AccountDto) => (option ? option?.username : "")}
              getOptionLabel={(option: AccountDto) =>
                option ? (option?.name ? `${option?.username} - ${option?.name}` : `${option?.username}`) : ""
              }
              loadOptions={options}
              placeholder={t(Labels.search_by_username)}
              onChange={(value: any) => {
                onFilterChange(
                  "vendorId",
                  value?.map((item: any) => ({ ...item, name: item.name || item.username }))
                )
              }}
            />
          </>
        )
      },
      hidden: true
    },
    {
      name: "customerNote",
      label: t(Labels.customer_note),
      type: "radio",
      value: [
        { label: Labels.yes, value: "1" },
        { label: Labels.no, value: "0" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
      hidden: true,
    },
    {
      name: "imageIssue",
      type: "checkbox",
      label: t(Labels.design_and_mockup),
      value: [
        // { label: 'Has Design Mockup', value: 'has_images' },
        // { label: 'Missing Design Mockup', value: 'no_images' },
        { label: Labels.missing_fulfill_design, value: "missing_fulfill_design" },
        { label: Labels.has_fulfill_design, value: "has_fulfill_design" },
        { label: Labels.has_design_mockup_error, value: "has_error" },
      ],
      hidden: true,
    },
    {
      name: "artworkStatus",
      label: t(Labels.artwork),
      type: "radio",
      value: [
        { label: Labels.no_artwork, value: "no_artwork" },
        { label: Labels.has_artwork, value: "has_artwork" },
        { label: Labels.has_artwork_no_img_url, value: "no_url_artwork" },
      ],
      hidden: true,
    },
    {
      name: "customType",
      label: t(Labels.customize),
      type: "checkbox",
      value: CustomTypeOptions,
      filterBuilder: values => ({ contains: values.map((value: any) => (value == "unknown" ? null : value)) }),
      hidden: true,
    },
    // {
    //   name: "customize",
    //   label: t("Customize (Deprecated)"),
    //   type: "radio",
    //   value: [
    //     {label: 'Yes', value: '1'},
    //     {label: 'No', value: '0'},
    //   ],
    //   filterBuilder: (value) => ({isEmpty: value != '1'}),
    //   hidden: true
    // },
    {
      name: "designCustomData",
      label: t(Labels.personal_custom_data),
      type: "radio",
      value: [
        { label: Labels.custom_created, value: "has_custom_data" },
        { label: Labels.custom_not_created, value: "custom_not_created" }
      ],
      filterBuilder: value => ({ isEmpty: value == 'custom_not_created' }),
      hidden: true,
    },
    {
      name: "customReviewed",
      label: t(Labels.personal_custom_status),
      type: "radio",
      value: [
        { label: Labels.reviewed, value: "1" },
        { label: Labels.wait_for_review, value: "0" },
      ],
      filterBuilder: value => ({ equalTo: value }),
      hidden: true,
    },
  ]

  let searchOptions: SearchOption[] = [
    {
      field: "orderNumber",
      label: t(Labels.order_number),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values.length <= 1 ? values[0] || "" : values }
      },
    },
    {
      field: "sku",
      label: t(Labels.fulfill_sku),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values.length <= 1 ? values[0] || "" : values }
      },
    },
    {
      field: "variationTitle",
      label: t(Labels.variant_title),
      filterBuilder: value => {
        return { contains: value.trim() }
      },
    },
    {
      field: "orderId",
      label: t(Labels.order_id),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values }
      },
    },
    {
      field: "id",
      label: t(Labels.id),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values }
      },
    },
  ]

  let actionOptions: ActionOption[] = [
    // {
    //   name: "change_fulfill_status",
    //   groupName: t(Labels.bulk_edit),
    //   label: t(Labels.change_fulfill_status),
    //   // eslint-disable-next-line react/display-name
    //   customRenderer: (menuItemComponent: any) => (
    //     <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
    //       {menuItemComponent}
    //     </WithPermission>
    //   ),
    //   onClick: async ({ selectedIds, refresh } = {} as SelectedRowActionProps) => {
    //     setPortableModal({
    //       open: true,
    //       title: t(Labels.change_fulfill_status),
    //       content: (
    //         <BulkUpdateSelectPopup
    //           options={FulfillStatusName}
    //           placeholder={t(Labels.choose_status_hint)}
    //           onSubmit={async (value: FulfillStatus) => value && FulfillOrderActions.bulkUpdateByIds(selectedIds, { status: value })}
    //           onDone={refresh}
    //         />
    //       ),
    //     })
    //   },
    // },
    // {
    //   name: "change_fulfill_status_issue",
    //   groupName: t(Labels.bulk_edit),
    //   label: t(Labels.change_fulfill_status_issue),
    //   // eslint-disable-next-line react/display-name
    //   customRenderer: (menuItemComponent: any) => (
    //     <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
    //       {menuItemComponent}
    //     </WithPermission>
    //   ),
    //   onClick: async ({ selectedIds, refresh } = {} as SelectedRowActionProps) => {
    //     setPortableModal({
    //       open: true,
    //       title: t(Labels.change_fulfill_status_issue),
    //       content: (
    //         <BulkUpdateSelectPopup
    //           options={FulfillStatusIssueName}
    //           placeholder={t(Labels.choose_status_issue_hint)}
    //           onSubmit={async (value: FulfillStatusIssue) =>
    //             value && FulfillOrderActions.bulkUpdateByIds(selectedIds, { statusIssue: value })
    //           }
    //           onDone={refresh}
    //         />
    //       ),
    //     })
    //   },
    // },
    // {
    //   name: "change_fulfill_substatus",
    //   groupName: t(Labels.bulk_edit),
    //   label: t(Labels.change_fulfill_design_status),
    //   // eslint-disable-next-line react/display-name
    //   customRenderer: (menuItemComponent: any) => (
    //     <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
    //       {menuItemComponent}
    //     </WithPermission>
    //   ),
    //   onClick: async ({ selectedIds, refresh } = {} as SelectedRowActionProps) => {
    //     setPortableModal({
    //       open: true,
    //       title: t(Labels.change_fulfill_design_status),
    //       content: (
    //         <BulkUpdateSelectPopup
    //           options={FulfillDesignStatusName}
    //           placeholder={`${t(Labels.choose_design_status)}...`}
    //           onSubmit={async (value: FulfillDesignStatus) => {
    //             if(value != null || value != undefined) {
    //               await FulfillOrderActions.bulkUpdateByIds(selectedIds, { designStatus: value })
    //             }
    //           }}
    //           onDone={refresh}
    //         />
    //       ),
    //     })
    //   },
    // },
    {
      name: "change_fulfill_status",
      groupName: t(Labels.bulk_edit),
      label: t(Labels.change_status),
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        return EditableFulfillStatuses.includes(selectData.activeTab?.id as any || '')
      },
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.change_status),
          content: (
            <>
              <BulkChangeFulfillStatusSelectPopup selectedIds={selectedIds} refresh={refresh} />
            </>
          ),
        })
      },
    },
    {
      name: "change_fulfill_service",
      label: t(Labels.change_fulfill_service),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillServiceEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.change_fulfill_service),
          content: (
            <BulkUpdateSelectPopup
              placeholder={`${t(Labels.choose_fulfill_service)}...`}
              selectBuilder={(onChange) => (
                <FulfillServiceSelect
                  isMulti={false}
                  autoFocus={true}
                  onChange={onChange}
                />
              )}
              onSubmit={async (value: any) => {
                value && await FulfillOrderActions.changeFulfillService(selectedIds, value?.id)
              }}
              onDone={refresh}
            />
          ),
        })
      },
    },
    {
      name: "edit_shipping_address",
      groupName: t(Labels.bulk_edit),
      label: t(Labels.edit_shipping_address),
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        return EditableFulfillStatuses.includes(selectData.activeTab?.id as any || '')
      },
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds } = {} as RemoteTableSelectionData) => {
        if(selectedIds){
          startBulkEdit(BulkEditorView.FULFILL_ORDER_SHIPPING_ADDRESS, selectedIds as string[])
          // history.push(`/fulfillments/orders?ids=${selectedIds.join(",")}&editor=${BulkEditorView.FULFILL_ORDER_SHIPPING_ADDRESS}`)
        }
      },
    },
    {
      name: "edit_selected_items",
      groupName: t(Labels.bulk_edit),
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        return EditableFulfillStatuses.includes(selectData.activeTab?.id as any || '')
      },
      label: t(Labels.edit_fulfill_information),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds } = {} as RemoteTableSelectionData) => {
        if(selectedIds){
          startBulkEdit(BulkEditorView.FULFILL_ORDER, selectedIds as string[])
          // history.push(`/fulfillments/orders?ids=${selectedIds.join(",")}&editor=${BulkEditorView.FULFILL_ORDER}`)
        }
      },
    },
    {
      name: "update_fulfill_product",
      label: t(Labels.update_fulfill_order_data),
      // eslint-disable-next-line react/display-name
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        let validStatuses = [FulfillStatus.ELIGIBLE, FulfillStatus.NOT_ELIGIBLE] as string[]
        return validStatuses.includes(selectData.activeTab?.id || '')
      },
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        await FulfillOrderActions.recreateFulfillProduct(selectedIds, refresh)
      },
    },
    {
      name: "upload_fulfill_design",
      label: t(Labels.create_fulfill_design),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        await FulfillOrderActions.uploadFulfillDesign(selectedIds, refresh)
      },
    },
    {
      name: "reload_design_url",
      label: t(Labels.update_fulfill_design_by_sku),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        await FulfillOrderActions.loadDesignUrls(selectedIds, refresh)
      },
    },
    {
      name: "set_fulfill_design_url",
      label: t(Labels.set_fulfill_design_url),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        const valueDesignUrl: any = {}
        setPortableModal({
          open: true,
          title: t(Labels.set_fulfill_design_url),
          content: (
            <>
              <ModalContent>
                <FormGroup className="mb-3">
                  <Label>{Labels.fulfill_mockup}</Label>
                  <Input type={"text"} placeholder={Labels.fulfill_mockup_hint}
                         value={valueDesignUrl.fulfillMockup}
                         onChange = {e => {valueDesignUrl.fulfillMockup = e.target.value}}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>{Labels.fulfill_design}</Label>
                  <Input type={"text"} placeholder={Labels.fulfill_design_hint}
                         value={valueDesignUrl.fulfillDesign}
                         onChange= {e => {valueDesignUrl.fulfillDesign = e.target.value}}
                  />
                </FormGroup>
              </ModalContent>
              <ModalButtons
                confirm={ async () => {
                  await FulfillOrderActions.bulkUpdateByIds(selectedIds,  valueDesignUrl )
                }
                }
              />
            </>
          ),
        })
      },
    },
    // {
    //   name: "export_for_supplier",
    //   groupName: t(Labels.export),
    //   label: t(Labels.export_for_fulfill_service),
    //   // eslint-disable-next-line react/display-name
    //   customRenderer: (menuItemComponent: any) => (
    //     <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
    //       {menuItemComponent}
    //     </WithPermission>
    //   ),
    //   onClick: ({ selectedIds } = {} as RemoteTableSelectionData) => handleExport(ExportType.FOR_FULFILL_SERVICE, selectedIds),
    // },
    // {
    //   name: "export_for_automation",
    //   groupName: t(Labels.export),
    //   label: t(Labels.export_for_auto_design),
    //   // eslint-disable-next-line react/display-name
    //   customRenderer: (menuItemComponent: any) => (
    //     <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
    //       {menuItemComponent}
    //     </WithPermission>
    //   ),
    //   onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
    //     setPortableModal({
    //       open: true,
    //       title: t(Labels.export_for_auto_design),
    //       size: "lg",
    //       content: <ExportDesignPopup handleExport={handleExport} filter={selectedIds} type={ExportType.FOR_AUTO_DESIGN}/>,
    //     })
    //   },
    // },
    // {
    //   name: "export_personal_custom",
    //   groupName: t(Labels.export),
    //   label: t(Labels.export_personal_custom_design),
    //   // eslint-disable-next-line react/display-name
    //   customRenderer: (menuItemComponent: any) => (
    //     <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
    //       {menuItemComponent}
    //     </WithPermission>
    //   ),
    //   onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
    //     setPortableModal({
    //       open: true,
    //       title: t(Labels.export_personal_custom_design),
    //       size: "lg",
    //       content: <ExportDesignPopup filter={selectedIds} type={ExportType.PERSONAL_CUSTOM}/>,
    //     })
    //   },
    // },
    {
      name: "review_personal_custom_data",
      groupName: t(Labels.bulk_edit),
      label: t(Labels.edit_personal_custom_data),
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        return FulfillStatus.IN_DESIGN == (selectData.activeTab?.id || '') && EditableFulfillStatuses.includes(selectData.activeTab?.id as any || '')
      },
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, selectedItems } = {} as RemoteTableSelectionData) => {
        if(selectedIds){
          startBulkEdit(BulkEditorView.REVIEW_CUSTOM, selectedIds as string[])
          // history.push(`/fulfillments/orders?ids=${selectedIds.join(",")}&editor=${BulkEditorView.REVIEW_CUSTOM}`)
        }
      },
    },
    {
      name: "create_personal_custom_data",
      label: t(Labels.create_personal_custom_data),
      // eslint-disable-next-line react/display-name
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        return FulfillStatus.IN_DESIGN == (selectData.activeTab?.id || '')
      },
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick:async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        if(selectedIds){
          await FulfillOrderActions.createDesignCustom(selectedIds, refresh);
        }
      },
    },
    {
      name: "change_custom_type",
      groupName: t(Labels.bulk_edit),
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        return EditableFulfillStatuses.includes(selectData.activeTab?.id as any || '')
      },
      label: t(Labels.change_custom_type),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.change_custom_type),
          content: (
            <>
              <BulkUpdateSelectPopup
                options={CustomTypeOptions}
                placeholder={t(Labels.choose_custom_type)}
                onSubmit={async (value: string) => {
                  return value && FulfillOrderActions.bulkUpdateByIds(selectedIds, { customType : value })}
                }
                onDone={refresh}
              />
            </>
          ),
        })
      },
    },
    {
      name: "create_fulfillment_order",
      label: t(Labels.request_fulfill_selected_orders),
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        let activeTabId = selectData.activeTab?.id || ''
        return [FulfillStatus.FULFILLABLE, FulfillStatus.FAILED].includes(activeTabId as any)
      },
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        let resData = await FulfillOrderActions.createFulfillmentOrder(selectedIds, refresh)
        resData && setPortableModal({
          open: true,
          title: t(Labels.request_fulfill_order_result_summary),
          size: 'lg',
          content: (
            <FulfillmentOrderResult
              data={resData?.data}
              onClose={() => {  }}
            />
          )
        })
      },
    },
    {
      name: "update_vendor",
      label: t(Labels.update_vendor),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.update_vendor),
          content: (
            <>
              <BulkUpdateVendorPopup
                onSubmit={async (value: any) => await FulfillOrderActions.changeVendor(selectedIds, value.id)}
                onDone={refresh}
              />
            </>
          ),
        })
      },
    },
    {
      name: "edit_fulfillment_variant",
      label: t(Labels.edit_fulfillment_variant),
      groupName: t(Labels.bulk_edit),
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        return EditableFulfillStatuses.includes(selectData.activeTab?.id as any || '')
      },
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds } = {} as RemoteTableSelectionData) => {
        if(selectedIds){
          startBulkEdit(BulkEditorView.FULFILL_ORDER_EDIT_VARIANT, selectedIds as string[])
        }
      },
    },
    {
      name: "edit_design_options",
      label: t(Labels.edit_design_option),
      groupName: t(Labels.bulk_edit),
      enableCondition: (action: ActionOption, selectData: RemoteTableSelectionData) => {
        return EditableFulfillStatuses.includes(selectData.activeTab?.id as any || '')
      },
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds } = {} as RemoteTableSelectionData) => {
        if(selectedIds){
          startBulkEdit(BulkEditorView.EDIT_DESIGN_OPTION, selectedIds as string[])
        }
      },
    },
    {
      name: "clone",
      label: t(Labels.clone),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.bulk_clone),
          content: (
            <>
              <BulkActionClonePopup
                onSubmit={async (value: any) => await FulfillOrderActions.bulkActionClone(selectedIds, value)}
                onDone={refresh}
                confirmText={Labels.clone}
              />
            </>
          ),
        })
      },
    },
  ]

  let tabOptions: TabOption[] = [
    {
      id: "all",
      label: t(Labels.all),
      count: countByStatus['all'] || 0,
      filter: {},
    },
    ...FulfillStatusValues.map(value => ({
      id: value,
      label: FulfillStatusName[value],
      filter: { status: { equalTo: value } },
      count: countByStatus[value] || 0,
      readonly: !EditableFulfillStatuses.includes(value)
    })),
  ]

  const getExportData = (idsOrFilter: string[] | number[] | QueryFilter<FulfillOrderDto>, exportData: string, customExportData?: DataTypeExportDesign) => {
    let filter = ((Array.isArray(idsOrFilter) && !isNaN(Number(idsOrFilter[0])) && exportData === exportRadioDataValues.selected)
      ? { id: { in: idsOrFilter }}
      : QueryBuilder.createFilter(queryState.filter as any)) as Filter<FulfillOrderDto>

    let dataExport: any = {...customExportData}
    let idsInCurrentPage = loadable.contents ? loadable.contents?.pageItems?.map((item: any) => item.id) : []

    switch(exportData) {
      case exportRadioDataValues.selected:
        if (!selectedIds.length) {
          Toasts.error(t(Messages.export_invalid))
          return
        } else {
          dataExport = {
            ...dataExport,
            filter: filter
          }
        }
        return dataExport
      case exportRadioDataValues.current_page:
        dataExport = {
          ...dataExport,
          filter: {id: {in: idsInCurrentPage}}
        }
        return dataExport
      case exportRadioDataValues.all_pages:
        dataExport = {
          ...dataExport,
          filter: filter
        }
        return dataExport
      default:
        return {}
    }
  }

  const handleExport = async (exportData: string, exportDataType: string, customExportData?: DataTypeExportDesign) => {
    let queryToExport = getExportData(selectedIds, exportData, customExportData)

    if (queryToExport) {
      let success: any = false
        if (exportType == ExportType.FOR_FULFILL_SERVICE) {
          success = await FulfillOrderActions.exportForFulfillServiceNew(queryToExport)
        } else if (exportType == ExportType.FOR_AUTO_DESIGN) {
          success = await FulfillOrderActions.exportForAutoDesignNew(queryToExport)
        } else if (exportType == ExportType.PERSONAL_CUSTOM) {
          success = await FulfillOrderActions.exportReviewedPersonalCustomNew(queryToExport)
        } else if (exportType == ExportType.MANUAL_CUSTOM) {
          success = await FulfillOrderActions.exportManual(queryToExport)
        }
      if (success) {
        Toasts.success(t(Messages.export_successfully))
        setPortableModal({ open: false, title: "" })
      }
    }
  }

  const handleExitBulkEditAction = () => {
    setBulkEditorState(undefined)
    history.push(RouteNames.ORDER_ITEMS)
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={t(Labels.order_items)}>
            { bulkEditorState?.editor ? (
              bulkEditorState.editor === BulkEditorView.REVIEW_CUSTOM ? 
                <ReviewCustomBulkEditAction
                  bulkEditSelector={bulkEditSelector}
                  onSubmit={(data) => FulfillOrderActions.saveReviewDesignCustom({ ...(data || {}) })}
                  onExit={() => handleExitBulkEditAction()}
                  dataRefresh={() => {
                    setBulkEditFulfillOrderIds({
                      ids: bulkEditorState.data.map((item: any) => item.id),
                      atomic: Date.now(),
                      editor: bulkEditorState.editor,
                    })
                  }}
                />
              	: bulkEditorState.editor === BulkEditorView.FULFILL_ORDER_EDIT_VARIANT 
                  ? <FulfillmentOrderEditFulfillVariantActions
                      editorRef={fulfillmentOrderEditorRef}
                      bulkEditSelector={bulkEditSelector}
                      onSubmit={data => FulfillOrderActions.saveEditFulfillmentOrders({ ...(data || {}) })}
                      onExit={() => handleExitBulkEditAction()}
                      dataRefresh={() => {
                        setBulkEditFulfillOrderIds({
                          ids: bulkEditorState.data.map((item: any) => item.id),
                          atomic: Date.now(),
                          editor: bulkEditorState.editor,
                        })
                      }}
                    /> 
                  : bulkEditorState.editor === BulkEditorView.EDIT_DESIGN_OPTION 
                    ? <ActionUploadDesign editorRef={fulfillmentOrderEditorRef}
                      onSubmit={async data => !!(await FulfillOrderActions.bulkUpdate({ ...(data || {}) }))}
                      dataRefresh={() => {
                      setBulkEditFulfillOrderIds({
                        ids: bulkEditorState.data.map((item: any) => item.id),
                        atomic: Date.now(),
                        editor: bulkEditorState.editor,
                      })
                    }}
                    onExit={() => handleExitBulkEditAction()}
                    bulkEditSelector={bulkEditSelector}
                    />
                    : <DefaultBulkEditAction
                      bulkEditSelector={bulkEditSelector}
                      onSubmit={async data => !!(await FulfillOrderActions.bulkUpdate({ ...(data || {}) }))}
                      onExit={() => handleExitBulkEditAction()}
                      dataRefresh={
                      () => setBulkEditFulfillOrderIds({ ids: bulkEditorState.data.map((item: any) => item.id), atomic: Date.now(), editor: bulkEditorState.editor })
                    }
                    />
            ) : (
              <TopBarButton
                changeImportType={setImportType}
                changeExportType={setExportType}
                queryStateFilter={queryState.filter}
              />
            )}
          </Breadcrumb>
          <ImportModal type={importType} onClose={() => setImportType(ImportType.NULL)} onDone={refreshData} replaceable={true}/>
          <ModalExportFulfillOrder
            type={exportType}
            onClose={() => setExportType(ExportType.NULL)}
            handleExport={handleExport}
            selectedIds={selectedIds}
            totalItems={totalItems}
          />

          { bulkEditorState?.editor ? (
              bulkEditorState.loading ? <Loading /> :
              bulkEditorState.editor === BulkEditorView.REVIEW_CUSTOM
                ? <PersonalCustomBulkEdit data={bulkEditorState.data} editor={bulkEditorState.editor} bulkEditSelector={bulkEditSelector} />
                : bulkEditorState.editor === BulkEditorView.FULFILL_ORDER_EDIT_VARIANT 
                  ? <FulfillmentOrderEditVariantBulkEditor
                      ref={fulfillmentOrderEditorRef}
                      data={bulkEditorState.data}
                      editor={bulkEditorState.editor}
                      bulkEditSelector={bulkEditSelector} 
                    />
                  : bulkEditorState.editor === BulkEditorView.EDIT_DESIGN_OPTION
                    ? <FulfillItemBulkUpload 
                      bulkEditSelector={bulkEditSelector}
                      ref={fulfillmentOrderEditorRef}
                      data={bulkEditorState.data}
                      editor={bulkEditorState.editor}
                    />
                    : <FulfillmentOrderBulkEditor data={bulkEditorState.data} editor={bulkEditorState.editor} bulkEditSelector={bulkEditSelector} />
            )
            :
            <RemoteTable
              dataLoadable={[loadable, setQueryState]}
              searchOptions={searchOptions}
              filterOptions={filterOptions}
              tabOptions={tabOptions}
              actionOptions={actionOptions}
              keyField={"id"}
              onSelectedChange={setSelectedIds}
              columns={columns}
              refresh={refreshData}
              sortOptions={[
                ...createDefaultSortOptions("id"),
                { field: "orderCreatedAt", direction: "asc", label: t(Labels.sort_order_create_oldest) },
                { field: "orderCreatedAt", direction: "desc", label: t(Labels.sort_order_create_newest) },
              ]}
              filterKey={'fulfillOrders'}
              filterByIds={searchParams ? searchParams.ids : undefined}
            />
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export interface TopBarButton {
  changeImportType: Function
  queryStateFilter: any
  changeExportType: Function
}

const TopBarButton = (props: TopBarButton) => {
  const setPortableModal = useSetRecoilState(portableModalState)
  const {changeExportType} = props

  return (
    <Fragment>
      {/*<DropdownComponent onMenuItemClick="toggle">*/}
      {/*  <DropdownComponent.Toggle>*/}
      {/*    <Button outline color="secondary" className="me-2">*/}
      {/*      {t("View Mode")}*/}
      {/*      <i className="bx bx-down-arrow ms-1"></i>*/}
      {/*    </Button>*/}
      {/*  </DropdownComponent.Toggle>*/}
      {/*  <DropdownComponent.Menu>*/}
      {/*    <div className="mb-0 p-2 overflow-auto bg-white" style={{ maxHeight: 295 }}>*/}
      {/*      <Button*/}
      {/*        outline*/}
      {/*        color="light"*/}
      {/*        className="border-0 d-block w-100 text-start"*/}
      {/*        onClick={ () => {  } }>*/}
      {/*        {t('Default View')}*/}
      {/*      </Button>*/}
      {/*      <Button*/}
      {/*        outline*/}
      {/*        color="light"*/}
      {/*        className="border-0 d-block w-100 text-start"*/}
      {/*        onClick={ () => {  } }>*/}
      {/*        {t('Review Design')}*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*  </DropdownComponent.Menu>*/}
      {/*</DropdownComponent>*/}
      <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillOrderEntity}>
        <DropdownComponent onMenuItemClick="toggle" alignRight>
          <DropdownComponent.Toggle>
            <Button outline color="secondary" className="me-2">
              {t(Labels.import)}
              <i className="bx bx-import ms-1"></i>
            </Button>
          </DropdownComponent.Toggle>
          <DropdownComponent.Menu>
            <div className="mb-0 p-2 overflow-auto bg-white" style={{ maxHeight: 295 }}>
              {/* <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => {
                  props.changeImportType(ImportType.FULFILLING)
                }}
              >
                {t(Labels.fulfilling_order)}
              </Button> */}
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => {
                  props.changeImportType(ImportType.FULFILLED)
                }}
              >
                {t(Labels.fulfilled_order)}
              </Button>
            </div>
          </DropdownComponent.Menu>
        </DropdownComponent>
      </WithPermission>
      <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
        <DropdownComponent onMenuItemClick="toggle" alignRight>
          <DropdownComponent.Toggle>
            <Button outline color="secondary" className="me-2">
              {t(Labels.export)}
              <i className="bx bx-export ms-1"></i>
            </Button>
          </DropdownComponent.Toggle>
          <DropdownComponent.Menu>
            <div className="mb-0 p-2 overflow-auto bg-white" style={{ maxHeight: 295 }}>
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => changeExportType(ExportType.FOR_FULFILL_SERVICE) }
              >
                {t(Labels.for_review)}
              </Button>
              {/* <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => changeExportType(ExportType.FOR_AUTO_DESIGN) }
              >
                {t(Labels.export_run_design_auto)}
              </Button> */}
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => changeExportType(ExportType.PERSONAL_CUSTOM)}
              >
                {t(Labels.personal_custom_design)}
              </Button>
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => changeExportType(ExportType.MANUAL_CUSTOM)}
              >
                {t(Labels.manual_custom_design)}
              </Button>
            </div>
          </DropdownComponent.Menu>
        </DropdownComponent>
      </WithPermission>
      <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillOrderEntity}>
        <Link {...createLinkEmbedParam(`${RouteNames.ORDER_ITEMS}/create`)}>
          <Button className="btn btn-success">
            <i className="bx bx-plus font-size-16 align-middle"></i>
            {t(Labels.create)}
          </Button>
        </Link>
      </WithPermission>
    </Fragment>
  )
}

import { useState } from "react"
import { useSetRecoilState } from "recoil"
import { portableModalState } from "data/atoms/app.atom"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import { Labels } from "common/labels"
import { t } from "core/translations"
import { Messages } from "common/messages"
import { Toasts } from "core/notification/notifications"
import { Input, Label } from "reactstrap"

export interface BulkActionClonePopupProps {
  onSubmit: (amount: number) => Promise<any>
  onDone?: Function
  confirmText?: string
}

export const BulkActionClonePopup = (props: BulkActionClonePopupProps) => {
  const { onSubmit, onDone, confirmText } = props
  const [value, setValue] = useState<any>()
  const setPortableModal = useSetRecoilState(portableModalState)

  const onClose = () => setPortableModal({ open: false, title: "" })

  return (
    <>
      <ModalContent>
        <Label className="mb-2">{t(Labels.quantity)}</Label>
        <Input type="number" placeholder={Labels.quantity_hint} value={value} min={1} onChange={e => setValue(+e.target.value)} />
      </ModalContent>
      <ModalButtons
        confirmText={confirmText}
        confirm={async () => {
          let data: any = null
          if (!+value) {
            return Toasts.warning(t(Labels.please_enter_amount))
          }

          data = await onSubmit(value)

          if (data) {
            Toasts.success(t(Messages.bulk_action_successfully))
            onDone && onDone()
            onClose()
          }
        }}
        closeAfterConfirm={false}
      />
    </>
  )
}

import React, { Fragment, useEffect, useState } from "react";
import { Badge, CardLink, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import RemoteTable, { createDefaultSortOptions } from "../../components/table/RemoteTable";
import { ColumnDescription } from "react-bootstrap-table-next";
import { orderFulfillmentStatusCountSelector, ordersQueryState, ordersSelector } from "../../data/atoms/orders.atom";
import { FilterOption, SearchOption, TabOption } from "../../components/table/TableFilter";
import { ActionOption, RemoteTableSelectionData } from "../../components/table/SelectedRowAction";
import { RouteNames } from "../../routes";
import { Link } from "react-router-dom";
import { BillingAddressType } from "../../types/address.type";
import format from "date-fns/format";
import { OrderStatusColor, OrderStatusName } from "../../types/order-status.type";
import {
  FulfillmentStatusColor,
  FulfillmentStatusName,
  FulfillmentStatusValues
} from "../../types/fulfillment-status.type";
import { Strings } from "../../utils/strings";
import { OrderServices } from "../../data/services/order.service";
import { t } from "../../core/translations";
import { Toasts } from "../../core/notification/notifications";
import { BulkUpdateSelectPopup } from "../../components/modal/bulk-update-popup";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { portableModalState } from "../../data/atoms/app.atom";
import { OrderActions } from "../../data/actions/order.action";
import { Labels } from "../../common/labels";
import { allSaleChannelsSelector } from "../../data/atoms/sale-channel.atom";
import { fulfillOrderStatusCountSelector } from "../../data/atoms/fulfill-order.atom";
import { FulfillOrderActions } from "../../data/actions/fulfill-order.action";
import { Messages } from "common/messages";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { TooltipComponent } from "components/common/TooltipComponent";
import { ModalButtons, ModalContent } from "components/modal/portable-modal";
import { LabelTooltip } from "../../components/common/LabelTooltip";
import { createDocumentPageName } from "../../utils/utils";
import { BiLinkExternal } from "react-icons/bi";
import { createLinkEmbedParam } from "utils/embed";

export const OrdersPage = (props: any) => {
  const setPortableModal = useSetRecoilState(portableModalState)
  const [saleChannels, setSaleChannels] = useState([])
  const saleChannelLoadable = useRecoilValueLoadable(allSaleChannelsSelector)
  const countByStatusLoadable = useRecoilValueLoadable(orderFulfillmentStatusCountSelector)
  const [countByStatus, setCountByStatus] = useState<any>({})

  useEffect(() => { document.title = createDocumentPageName(t(Labels.orders)) }, [])

  useEffect(() => {
    if(saleChannelLoadable.state == 'hasValue') {
      setSaleChannels(saleChannelLoadable.contents || [])
    }
  }, [saleChannelLoadable.state])

  useEffect(() => {
    if(countByStatusLoadable.state == 'hasValue') {
      setCountByStatus(countByStatusLoadable.contents || {})
    }
  }, [countByStatusLoadable.state])

  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { minWidth: 90, width: 90},
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.ORDERS}/${row.id}`)}> {`${cell}`}</Link>
        </>;
      },
      hidden: true
    },
    {
      dataField: "number",
      text: t(Labels.order_number),
      headerStyle: { minWidth: 120, width: 140},
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Fragment>
          <Col>
            <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.ORDERS}/${row.id}`)}> {`${cell}`}</Link>
              {
                !row.saleChannel?.domain || !row.channelOrderId ? null :
            <Row>

              <Col xs={"auto"} className="pe-0">
                <TooltipComponent tooltip={t(Labels.channel_order_id)}>
                  { row.channelOrderId }
                </TooltipComponent>
              </Col>
              <Col className="ps-1">
                <CardLink target="_blank" href={`https://${row.saleChannel.domain}/wp-admin/post.php?action=edit&post=${row.channelOrderId}`} >
                  <BiLinkExternal size={16}/>
                </CardLink>
              </Col>
            </Row>
              }
          </Col>
        </Fragment>;
      }
    },
    {
      dataField: "orderCreatedAt",
      text: t(Labels.order_date),
      headerStyle: { width: 180 },
      formatter: (cell: any, row: any) => {
        return format(new Date(cell), "MMM dd, yyyy HH:mm");
      }
    },
    {
      dataField: "status",
      text: t(Labels.status),
      headerStyle: { minWidth: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell) => {
        let color = OrderStatusColor[cell] || "light";
        return <>
          <Badge color={color} pill>{OrderStatusName[cell] || cell}</Badge>
        </>;
      }
    },
    {
      dataField: "fulfillmentStatus",
      text: t(Labels.fulfillment_status),
      headerStyle: { width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        let color = FulfillmentStatusColor[cell] || "light"; // TODO change color by status
        return <>
          <TooltipComponent tooltip={t(row.fulfillmentIssue || "")}>
            <Badge color={color} pill>{FulfillmentStatusName[cell] || cell}</Badge>
          </TooltipComponent>
        </>;
      }
    },
    {
      dataField: "fulfillmentIssue",
      text: t(Labels.message),
      headerStyle: { minWidth: 130 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return row.fulfillmentIssue || ""
      }
    },
    {
      dataField: "total",
      text: t(Labels.total),
      headerStyle: { minWidth: 100 },
      formatter: (cell: any, row: any) => {
        return `$${cell}`;
      }
    },
    {
      dataField: "lineItems",
      text: Labels.items,
      headerStyle: { minWidth: 100 },
      formatter: (cell: any, row: any) => {
        return `${cell?.length || 0} items`;
      }
    },
    {
      dataField: "billingAddress",
      text: t(Labels.customer),
      headerStyle: { minWidth: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: BillingAddressType, row: any) => {
        return <Col>
          <Row><h6 className="ps-0 mb-0"> {`${cell.name || ""}`} </h6></Row>
          <Row>{Strings.getAddress(cell)}</Row>
        </Col>;
      }
    }
  ];

  let filterOptions: FilterOption[] = [
    {
      name: "channelId",
      label: t(Labels.title_sale_channel),
      type: "radio",
      value: saleChannels?.map((channel: any) => ({ value: channel.id, label: channel.name })) || []
    },
    {
      name: "orderCreatedAt",
      label: t(Labels.order_date),
      type: "date",
      filterBuilder: (value) => ({ between: value }),
      hidden: true
    },
    {
      name: "childId",
      label: t(Labels.has_upsell_order),
      type: "radio",
      value: [
        { label: Labels.yes, value: "1" },
        { label: Labels.no, value: "0" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
      hidden: true,
    },
  ];

  let searchOptions: SearchOption[] = [
    {
      field: "number",
      label: t(Labels.order_number),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values.length <= 1 ? values[0] || "" : values }
      },
    },
    {
      field: "channelOrderId",
      label: t(Labels.channel_order_id),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values }
      },
    },
    {
      field: "id",
      label: t(Labels.id),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values }
      },
    },
  ];

  let actionOptions: ActionOption[] = [
    {
      name: "fulfill",
      label: t(Labels.create_order_items),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.orderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      // onClick: async ({ selectedIds, refresh } = {} as SelectedRowActionProps) => {
      //   let result = await OrderServices.fulfillOrders(selectedIds)
      //   if(result?.success) {
      //     Toasts.success(t(Messages.required_fulfill_order, { number: selectedIds.length }))
      //     refresh && refresh()
      //   } else {
      //     Toasts.error(t(Messages.your_request_execute_unsuccessfully))
      //   }
      // }
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        const value: any = {}
        setPortableModal({
          open: true,
          title: t(Labels.create_order_items),
          content: (
            <>
              <ModalContent>
                <LabelTooltip tooltip={t(Labels.default_sku_input_help)}>
                  <Label>
                    {t(Labels.default_sku)}
                  </Label>
                </LabelTooltip>
                <FormGroup className="mb-3">
                  <Input
                    type={"text"}
                    placeholder={Labels.default_sku_input_hint}
                    value={value.fulfillMockup}
                    onChange={e => {
                      value.defaultSku = e.target.value
                    }}
                  />
                </FormGroup>
              </ModalContent>
              <ModalButtons
                confirmText={t(Labels.fulfill)}
                confirm={async () => {
                  let result = await OrderServices.fulfillOrders(selectedIds, value)
                  if(result?.success) {
                    Toasts.success(t(Messages.required_fulfill_order, { number: selectedIds.length }))
                    refresh && refresh()
                  } else {
                    Toasts.error(t(Messages.your_request_execute_unsuccessfully))
                  }
                }}
              />
            </>
          ),
        })
      },
    },
    {
      name: "change_status",
      label: t(Labels.change_order_status),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.orderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.change_order_status),
          content: (
            <BulkUpdateSelectPopup
              options={OrderStatusName}
              placeholder={t(Labels.choose_status_hint)}
              onSubmit={async (value: any) => value && OrderActions.bulkUpdateByIds(selectedIds, { status: value })}
              onDone={refresh}
            />
          )
        })
      }
    },
    {
      name: "change_fulfillment_status",
      label: t(Labels.change_fulfillment_status),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.orderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.change_fulfillment_status),
          content: (
            <BulkUpdateSelectPopup
              options={FulfillmentStatusName}
              placeholder={t(Labels.choose_status_hint)}
              onSubmit={async (value: any) => value && OrderActions.bulkUpdateByIds(selectedIds, { fulfillmentStatus: value })}
              onDone={refresh}
            />
          )
        })
      }
    },
  ];

  let tabOptions: TabOption[] = [
    {
      id: "all",
      label: t(Labels.all),
      filter: {}
    },
    ...FulfillmentStatusValues.map(value => ({
      id: value,
      label: t(FulfillmentStatusName[value]),
      filter: { fulfillmentStatus: { equalTo: value } },
      count: countByStatus[value] || 0
    }))
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <RemoteTable
            dataLoader={[ordersSelector, ordersQueryState]}
            searchOptions={searchOptions}
            filterOptions={filterOptions}
            tabOptions={tabOptions}
            sortOptions={[
              ...createDefaultSortOptions('id'),
              { field: "orderCreatedAt", direction: "asc", label: t(Labels.sort_order_create_oldest) },
              { field: "orderCreatedAt", direction: "desc", label: t(Labels.sort_order_create_newest) },
            ]}
            actionOptions={actionOptions}
            keyField={"id"}
            columns={columns} 
            filterKey={'orders'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrdersPage;

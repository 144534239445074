export enum ChannelSyncStatus {
    SUCCESS = 'success',
    SYNCING = 'syncing',
    ERROR = 'error',
    PENDING = 'pending'
}

export const ChannelSyncStatusValues: ChannelSyncStatus[] = [
    ChannelSyncStatus.SUCCESS,
    ChannelSyncStatus.SYNCING,
    ChannelSyncStatus.ERROR,
    ChannelSyncStatus.PENDING,
]

export const ChannelSyncStatusName: Record<string, string> = {
    [ChannelSyncStatus.SUCCESS]: 'success',
    [ChannelSyncStatus.SYNCING]: 'syncing',
    [ChannelSyncStatus.ERROR]: 'error',
    [ChannelSyncStatus.PENDING]: 'pending'
}

export const ChannelSyncStatusColor: Record<string, string> = {
    [ChannelSyncStatus.SUCCESS]: 'success',
    [ChannelSyncStatus.SYNCING]: 'primary',
    [ChannelSyncStatus.ERROR]: 'danger',
    [ChannelSyncStatus.PENDING]: 'primary',
}
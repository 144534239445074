import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardLink,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Table
} from "reactstrap";
import { fulfillOrderByOrderIdState } from "../../data/atoms/fulfill-order.atom";

import { t } from "../../core/translations";
import { Strings } from "../../utils/strings";
import { Link, useHistory, useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable } from "recoil";
import Breadcrumb from "../../components/layout/Breadcrumb";
import ImagExample from "../../assets/images/companies/img-1.png";
import { LoadableComponent } from "../../components/common/LoadableComponent";
import { orderByIdSelector } from "../../data/atoms/orders.atom";
import { OrderStatusColor, OrderStatusName } from "../../types/order-status.type";
import { FormLayout, FormLayoutMain, FormLayoutRight } from "../../components/layout/FormLayout";
import format from "date-fns/format";
import { isEmpty, isString } from "lodash";
import { Metafield } from "../../types/metafield.type";
import { OrderDto } from "../../data/services/order.service";
import { FulfillOrdersPortable } from "../fulfillments/components/fulfill-orders.portable";
import { FulfillmentStatusColor, FulfillmentStatusName } from "../../types/fulfillment-status.type";
import { convertToReactElement, preprocessPage } from "../../utils/utils";
import { Labels } from "../../common/labels";
import { RouteNames } from "../../routes";
import { BiLinkExternal } from "react-icons/bi";
import { isURL } from "class-validator";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { OrderActions } from "data/actions/order.action";
import { createLinkEmbedParam, createUrlEmbed } from "utils/embed";

const LineItemsComponent = (props: { lineItems?: any[] }) => {
  const [expandLineItemId, setExpandLineItemId] = React.useState(-1);
  return (
    <>
      {props.lineItems?.map((lineItem: any, i: number) =>
        <div key={i}>
          <Row className="no-gutters mb-3 p-0">
            <Col md={4} style={{ maxWidth: 150, padding: 10 }}>
              <CardImg className="img-fluid" src={lineItem.image ? lineItem.image : ImagExample }/>
            </Col>

            <Col md={9} className="mt-2 pe-0">
              <Row className="p-0">
                <Col>
                  <CardTitle className="h5">{lineItem.name ? lineItem.name : ""}</CardTitle>
                </Col>
                <Col xs="auto">
                  ${lineItem.price} x {lineItem.quantity}
                </Col>
                <Col xs="auto">
                  <strong>${lineItem.total}</strong>
                </Col>
              </Row>
              <div>
                <span>{Labels.sku_uppercase}:</span> <span className="ms-1">{lineItem.sku}</span>
                {
                  lineItem.metafields
                    .slice(0, expandLineItemId == lineItem.id ? lineItem.metafields.length : Math.min(2, lineItem.metafields.length))
                    .map(
                      (item: any, index: number) => {
                        return (
                          <div className="py-1" key={index}>
                            <span className='fw-bold'>{item.key}:</span> 
                              <span className="ms-1">
                                {
                                expandLineItemId == lineItem.id ? convertToReactElement(item.value) : `${convertToReactElement(item.value).slice(0,100)}...`
                                }
                              </span>
                          </div>
                        )
                      }
                    )
                }
              </div>
              <div
                id="remaining"
                onClick={() => setExpandLineItemId((current) => current == lineItem.id ? -1 : lineItem.id)}
                className="text-primary font-size-13 align-middle text-primary me-2 mt-1">
                { expandLineItemId == lineItem.id ? t(Labels.hide_remaining_line_item) : t(Labels.show_remaining_line_item)}
              </div>
            </Col>
          </Row>
          <div className="dropdown-divider" />
        </div>
      )
      }
    </>
  );
};

const ProductDetail = (props: { data: OrderDto }) => {
  return (
    <Card>
      <CardHeader>
        <Row className="p-0 m-0">
          <Col className="p-0 m-0 pt-2">
            <h5 className="m-0 py-2"> {`${Labels.order} #${props.data?.number} - $${props.data?.total}`}</h5>
          </Col>
          <Col xs="auto" className="p-0 m-0 pt-3">
            <span className="me-0 text-end py-2">{props.data?.orderCreatedAt ? format(new Date(props.data?.orderCreatedAt), "MMM dd, yyyy HH:mm") : ""} </span>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <LineItemsComponent lineItems={props.data?.lineItems || []} />
        <div className="table-responsive mb-4">
          <Table className="table mb-0">
            <tbody>
              <tr>
                <td>{t(Labels.subtotal)}</td>
                <td className="text-center">{props.data?.lineItems?.length || 0} {Labels.items}</td>
                <td className="text-end">$ {props.data?.subtotal || 0}</td>
              </tr>
              <tr>
                <td>{t(Labels.shipping)}</td>
                <td className="text-center">{props.data?.shippingMethod || ""}</td>
                <td className="text-end">$ {props.data?.shippingTotal || 0}</td>
              </tr>
              <tr>
                <td><strong>{t(Labels.total)}</strong></td>
                <td className="text-center"></td>
                <td className="text-end"><strong>$ {props.data?.total || 0}</strong></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

const CustomFields = (props: { data: OrderDto }) => {
  let dataFields = props.data?.metafields?.filter((meta: Metafield) => !!meta.value)
  return (
    <Card>
      <CardHeader>
        <h5 className="m-0 py-2">{t(Labels.custom_fields)}</h5>
      </CardHeader>
      <div className="table-responsive">
        <Table className="table mb-0 table-bordered">
          <tbody>
          { dataFields?.map((specification: any, i) => (
              <tr key={i}>
                <td className="ps-3" style={{ maxWidth: 260, borderRight: 0 }}>{specification.key}</td>
                <td className="text-break me-3">
                  {
                    !isString(specification.value) ? JSON.stringify(specification.value)
                      : isURL(specification.value) // TODO optimize check fn
                        ? <a target={"_blank"} rel="noreferrer" href={specification.value}>{specification.value}</a>
                          : specification.value
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};

const OrderStatus = (props: { data: OrderDto }) => {
  const [channelOrderIdByChildId, setChannelOrderIdByChildId] = useState("")
  const [channelOrderIdByParentId, setChannelOrderIdByParentId] = useState("")
  const loadable = async () => {
    if(+props.data.childId > 0){
      const orderByChildId = await OrderActions.getChannelOrderIdById(props.data.childId)
      setChannelOrderIdByChildId(orderByChildId.id)
    }
    if(+props.data.parentId > 0){
      const orderByParentId = await OrderActions.getChannelOrderIdById(props.data.parentId)
      setChannelOrderIdByParentId(orderByParentId.id)
    }
  }
  useEffect(() => {
    loadable()
  }, [props.data.childId, props.data.parentId])

  return (
    <Card>
      <CardBody>
        <FormGroup className="mb-3 ajax-select select2-container">
          <Label> {t(Labels.order_status)}</Label>
          <div>
            <Badge color={OrderStatusColor[props.data?.status] || "light"} pill>
              {OrderStatusName[props.data?.status] || props.data?.status}
            </Badge>
          </div>
        </FormGroup>

        <FormGroup className="mb-3 ajax-select select2-container">
          <Label> {t(Labels.fulfillment_status)}</Label>
          <div className="d-flex flex-column gap-2 align-items-start">
            <Badge color={FulfillmentStatusColor[props.data?.fulfillmentStatus] || "light"} pill>
              {FulfillmentStatusName[props.data?.fulfillmentStatus] || props.data?.fulfillmentStatus}
            </Badge>
            {props.data?.fulfillmentIssue ? (
              <Alert color={FulfillmentStatusColor["error"]} className='p-1 font-size-11'  pill>
                {props.data?.fulfillmentIssue}
              </Alert>
            ) : (
              <></>
            )}
          </div>
        </FormGroup>

        <div className="mt-3">
          <Label>{t(Labels.customer_notes)}</Label>
          <div style={{ minHeight: 70, borderRadius: 5, padding: 10, border: "1px solid #f3f4f6" }}>
            <p className="text-muted mb-0">{props.data?.customerNote ? props.data?.customerNote : t(Labels.no_note_customer)}</p>
          </div>
        </div>
        {/* xuanduc edit */}
        <div className="mt-4">
          <Row>
            <Col xs={"auto"} className="pe-0">
              <Label>{t(Labels.view_on_metorik)}</Label>
            </Col>
            <Col>
              <CardLink href={`https://app.metorik.com/orders/${props.data?.channelOrderId}`} target="_blank">
                <BiLinkExternal size="18" />
              </CardLink>
            </Col>
          </Row>
        </div>
        <div className="mt-1">
          <Row>
            <Col xs={"auto"} className="pe-0">
              <Label>{t(Labels.view_on_store_admin)}</Label>
            </Col>
            <Col>
              <CardLink
                href={`https://${props.data?.saleChannel?.domain}/wp-admin/post.php?action=edit&post=${props.data?.channelOrderId}`}
                target="_blank"
              >
                <BiLinkExternal size="18" />
              </CardLink>
            </Col>
          </Row>
        </div>
        {Number(props.data.childId) > 0 ? (
          <div className="mt-1">
            <Row>
              <Col xs={"auto"} className="pe-0">
                <Label>{t(Labels.upsell_order)}</Label>
              </Col>
              <Col>
                <CardLink
                  href={createUrlEmbed(`${RouteNames.ORDERS_DETAIL.replace(":id", channelOrderIdByChildId)}?upsell_order_id=${props.data.childId}`)}
                  target="_blank"
                >
                  <BiLinkExternal size="18" />
                </CardLink>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}
        {Number(props.data.parentId) > 0 ? (
          <div className="mt-1">
            <Row>
              <Col xs={"auto"} className="pe-0">
                <Label>{t(Labels.parent_order)}</Label>
              </Col>
              <Col>
                <CardLink href={createUrlEmbed(`${RouteNames.ORDERS_DETAIL.replace(":id", channelOrderIdByParentId)}`)} target="_blank">
                  <BiLinkExternal size="18" />
                </CardLink>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}
      </CardBody>
    </Card>
  )
};

export const AddressDetail = (props: { address: any}) => {
  return !props?.address ? null : (
    <div className="ms-2 mb-3">
      {
        Strings.getOrderBillingAdders(props.address).map((item, key) => (
          <React.Fragment key={key}>
            <span>{item.trim()}</span>
            <br />
          </React.Fragment>
        ))
      }
    </div>
  );
}

const ContactInformation = (props: { data: OrderDto }) => {
  return (
    <Card>
      <CardBody>
      <Label>{t(Labels.contact_infomation)}</Label>
      <div className="table-responsive text-muted">
        <div className="ms-2 me-2">
          <div><a href="#"> {props.data?.phone || ""}</a></div>
          <div><a href="#"> {props.data?.email || ""} </a></div>
        </div>
      </div>
      <div className="mt-3">
        <Label>{t(Labels.shipping_address)}</Label>
        <AddressDetail address={props.data?.shippingAddress}/>
        <Label>{t(Labels.billing_address)}</Label>
        <AddressDetail address={props.data?.billingAddress}/>
      </div>
      </CardBody>
    </Card>
  );
};

export const OrderPage = () => {
  const params = useParams<Record<string, any>>();
  const dataLoadable = useRecoilValueLoadable(orderByIdSelector(params.id));
  const history = useHistory()
  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(orderByIdSelector(params.id))
  useEffect(() => {
    ((dataLoadable.state == "hasValue"  || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[params.id])
  
  const [fulfillOrderById, setFulfillOrderById] = useRecoilState(fulfillOrderByOrderIdState(params.id))

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.order), model: dataLoadable.contents, primaryProperty: 'number'})
  }, [dataLoadable.state])

  const handleFulfillThisOrder = async () => {
    await OrderActions.recreateFulfillProduct([params.id as number], () => setFulfillOrderById(+params.id + 0))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb goBack={RouteNames.ORDERS} title={t(Labels.channel_order)}>
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillOrderEntity}>
              <Col className="m-0 p-0" xs={'auto'}>
                <Button
                  type="button"
                  color="primary"
                  className="me-2"
                  onClick={handleFulfillThisOrder}
                >
                  {t(Labels.update_fulfill_order_data)}
                </Button>
                <Link {...createLinkEmbedParam(`${RouteNames.ORDER_ITEMS}/create?orderId=${params.id}`)}>
                  <Button
                    type="button"
                    className="btn btn-success"
                  >
                    <i className="bx bx-plus font-size-16 align-middle"/>
                    {t(Labels.create_order_item)}
                  </Button>
                </Link>
              </Col>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable}>
            <FormLayout>
              <FormLayoutMain>
                <ProductDetail data={dataLoadable.contents} />
                <FulfillOrdersPortable orderId={params.id}/>
                <CustomFields data={dataLoadable.contents} />
              </FormLayoutMain>
              <FormLayoutRight>
                <OrderStatus data={dataLoadable.contents || {}} />
                <ContactInformation data={dataLoadable.contents} />
              </FormLayoutRight>
            </FormLayout>
          </LoadableComponent>
        </Container>
      </div>
    </React.Fragment>
  );
};
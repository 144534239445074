import { Messages } from "common/messages";
import { CrudActions } from "./crud.action";
import { Filter, QueryBuilder, QueryFilter, QueryParams } from "../../core/rest-api/query-builder";
import {
  CreateFulfillOrderDto,
  FulfillOrderApiPaths,
  FulfillOrderDto,
  FulfillOrderService,
  FulfillOrderServices,
  UpdateFulfillOrderDto
} from "../services/fulfill-order.service";
import { Logger } from "../../core/logger";
import FileSaver from "file-saver";
import { Confirmations, Toasts } from "../../core/notification/notifications";
import { t } from "core/translations";
import { isEmpty, omit } from "lodash";
import { RemotePageData } from "../../components/table/RemoteTable";
import { AdminUtils } from "../../utils/admin-utils";
import { OrderActionEnum } from "../services/order.service";
import { toUint8Array } from "../../utils/utils";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { withPermission } from "common/hooks/use-permission";
import { Labels } from "common/labels";

class FulfillOrderAction extends CrudActions<FulfillOrderService, CreateFulfillOrderDto, UpdateFulfillOrderDto>{
  constructor() {
    super(FulfillOrderServices);
  }

  async findManyByIds(ids: any[]) {
    try {
      let data: RemotePageData = await FulfillOrderServices.getByIds(ids)
      return data?.pageItems || []
    } catch (e) {
      return []
    }
  }


  async createFulfillDesign(id: string) {
    try {
      let data = await FulfillOrderServices.createFulfillDesign(id);
      return data
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  }

  async requestFulfillOrder(id: string) {
    try {
      let data = await FulfillOrderServices.requestFulfillOrder(id);
      return data
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  }

  async importFulfilledOrders(files: any[], replaceable?: boolean) {
    try {
      const formData = new FormData();
      files.forEach(file => formData.append('files', file))
      const data: any = await FulfillOrderServices.importFulfilledOrders(formData, replaceable)
      return data
    } catch (e) {
      Logger.warn('importFulfilledOrders failed: ', e)
    }
    return false
  }

  async importFulfillingOrders(files: any[], replaceable?: boolean) {
    try {
      const formData = new FormData();
      files.forEach(file => formData.append('files', file))
      const data: any = await FulfillOrderServices.importFulfillingOrders(formData, replaceable)
      return data
    } catch (e) {
      Logger.warn('importFulfillingOrders failed: ', e)
    }
    return false
  }

  async exportForFulfillService(idsOrFilter: string[] | number[] | QueryFilter<FulfillOrderDto>) {
    let filter = ((Array.isArray(idsOrFilter) && !isNaN(Number(idsOrFilter[0])))
      ? { id: { in: idsOrFilter }}
      : QueryBuilder.createFilter(idsOrFilter as any)) as Filter<FulfillOrderDto>
    try {
      let data: any = await FulfillOrderServices.exportForFulfillService({ filter });
      if(data?.type == 'Buffer') {
        let uint8Array = toUint8Array(data.data);
        const file = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(file, `fulfill-order-for-fulfill-service_${AdminUtils.getSavingFileSubName()}.xlsx`);
        return true
      }
    } catch (e) {
      Toasts.error(t(Messages.export_unsuccessfully))
      Logger.warn('exportForFulfillService failed: ', e)
    }
  }

  async exportForAutoDesign(idsOrFilter: string[] | number[] | Filter<FulfillOrderDto> | Filter<FulfillOrderDto>[], value: any) {
    let filter = ((Array.isArray(idsOrFilter) && !isNaN(Number(idsOrFilter[0])))
      ? { id: { in: idsOrFilter }}
      : QueryBuilder.createFilter(idsOrFilter as any)) as Filter<FulfillOrderDto>
    let values: QueryParams<FulfillOrderDto> = {
      filter,
      ...value
    }
    try {
      let data: any = await FulfillOrderServices.exportForAutoDesign( values );
      if(data?.type == 'Buffer') {
        const file = new Blob([toUint8Array(data.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(file, `fulfill-order-for-auto-design_${AdminUtils.getSavingFileSubName()}.xlsx`);
        return true
      }
    } catch (e) {
      Toasts.error(t(Messages.export_unsuccessfully))
      Logger.warn('exportForAutoDesign failed: ', e)
    }
  }

  async exportReviewedPersonalCustom(idsOrFilter: string[] | number[] | Filter<FulfillOrderDto> | Filter<FulfillOrderDto>[], value: any) {
    let filter = ((Array.isArray(idsOrFilter) && !isNaN(Number(idsOrFilter[0])))
      ? { id: { in: idsOrFilter }}
      : QueryBuilder.createFilter(idsOrFilter as any)) as Filter<FulfillOrderDto>
    let values: QueryParams<FulfillOrderDto> = {
      filter,
      ...value
    }
    try {
      let data: any = await FulfillOrderServices.exportReviewedPersonalCustom( values );
      if(data?.type == 'Buffer') {
        const file = new Blob([toUint8Array(data.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(file, `fulfill-order-personal-custom_${AdminUtils.getSavingFileSubName()}.xlsx`);
        return true
      }
    } catch (e) {
      Toasts.error(t(Messages.export_unsuccessfully))
      Logger.warn('exportForAutoDesign failed: ', e)
    }
  }

  async exportForFulfillServiceNew(filter: any) {
    try {
      let data: any = await FulfillOrderServices.exportForFulfillService(filter);
      if(data?.type == 'Buffer') {
        let uint8Array = toUint8Array(data.data);
        const file = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(file, `fulfill-order-for-fulfill-service_${AdminUtils.getSavingFileSubName()}.xlsx`);
        return true
      }
    } catch (e) {
      Toasts.error(t(Messages.export_unsuccessfully))
      Logger.warn('exportForFulfillService failed: ', e)
    }
  }

  async exportForAutoDesignNew(filter: any) {
    let values: QueryParams<FulfillOrderDto> = filter
    try {
      let data: any = await FulfillOrderServices.exportForAutoDesign( values );
      if(data?.type == 'Buffer') {
        const file = new Blob([toUint8Array(data.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(file, `fulfill-order-for-auto-design_${AdminUtils.getSavingFileSubName()}.xlsx`);
        return true
      }
    } catch (e) {
      Toasts.error(t(Messages.export_unsuccessfully))
      Logger.warn('exportForAutoDesign failed: ', e)
    }
  }

  async exportReviewedPersonalCustomNew(filter: any) {
    let values: QueryParams<FulfillOrderDto> = filter
    try {
      let data: any = await FulfillOrderServices.exportReviewedPersonalCustom( values );
      if(data?.type == 'Buffer') {
        const file = new Blob([toUint8Array(data.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(file, `fulfill-order-personal-custom_${AdminUtils.getSavingFileSubName()}.xlsx`);
        return true
      }
    } catch (e) {
      Toasts.error(t(Messages.export_unsuccessfully))
      Logger.warn('exportForAutoDesign failed: ', e)
    }
  }

  async exportManual(filter: any) {
    let values: QueryParams<FulfillOrderDto> = filter
    try {
      let data: any = await FulfillOrderServices.exportManual( values );
      if(data?.type == 'Buffer') {
        const file = new Blob([toUint8Array(data.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(file, `fulfill-order-manual-custom_${AdminUtils.getSavingFileSubName()}.xlsx`);
        return true
      }
    } catch (e) {
      Toasts.error(t(Messages.export_unsuccessfully))
      Logger.warn('exportForAutoDesign failed: ', e)
    }
  }

  async loadDesignUrls(ids: string[] | number[], refresh: Function) {
    let result = await FulfillOrderServices.loadDesignUrls(ids)
    if(result?.success) {
      Toasts.success(t(Messages.required_upload_design_image, { number: ids.length }))
      refresh && refresh()
    } else {
      Toasts.error(t((Messages.your_request_execute_unsuccessfully)))
    }
  }

  async uploadFulfillDesign(ids: string[] | number[], refresh: Function) {
    let result = await FulfillOrderServices.uploadFulfillDesign(ids)
    if(result?.success) {
      Toasts.success(t(Messages.required_upload_fulfill_design_done, { number: ids.length }))
      refresh && refresh()
    } else {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  }

  async createFulfillmentOrder(ids: string[] | number[], refresh: Function) {
    let result = await FulfillOrderServices.createFulfillmentOrder(ids)
    if(result?.success) {
      Toasts.success(t(Messages.required_fulfillment_order_done, { number: ids.length }))
      refresh && refresh()
    } else {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
    return result
  }

  async changeFulfillService(ids: string[] | number[], fulfillServiceId?: string | number) {
    return super.runBulkAction(FulfillOrderApiPaths.ACTIONS,  ids, OrderActionEnum.CHANGE_FULFILL_SERVICE, { fulfillServiceId: fulfillServiceId as number })
  }

  async setDesignUrlService(ids: string[] | number[], fulfillServiceId?: string | number) {
    return super.runBulkAction(FulfillOrderApiPaths.ACTIONS,  ids, OrderActionEnum.SET_FULFILL_DESIGN_URL, { fulfillServiceId: fulfillServiceId as number })
  }

  async bulkUpdate(bulkUpdateData: Record<string, any>) {
    for(let key in bulkUpdateData) {
      let value = {...bulkUpdateData[key]}
      value.supplierSku = value.newSupplierSku
      bulkUpdateData[key] = omit(value, 'newSupplierSku')
    }
    return super.bulkUpdate(bulkUpdateData)
  }

  async saveReviewDesignCustom(bulkUpdateData: Record<string, any>) {
    let result = await FulfillOrderServices.saveReviewDesignCustom(bulkUpdateData)
    result ? Toasts.success(t(Messages.update_successfully)) : Toasts.error(t(Messages.update_unsuccessfully))
    return !!result
  }

  async saveEditFulfillmentOrders(bulkUpdateData: Record<string, any>) {
    let result = await FulfillOrderServices.saveEditFulfillmentOrders(bulkUpdateData)
    result ? Toasts.success(t(Messages.update_successfully)) : Toasts.error(t(Messages.update_unsuccessfully))
    return !!result
  }

  async bulkEditChangeFulfillService(ids: string[], fulfillServiceId?: string | number) {
    try {
      let result: any = await FulfillOrderServices.bulkChangeFulfillService(ids, fulfillServiceId)
      if(result.data){
        Toasts.success(t(Messages.changed_fulfill_service_in_bulk_fulfillment_order))
      }
      // success ? Toasts.success(t(Messages.update_successfully)) : Toasts.error(t(Messages.update_unsuccessfully))
      return result.data
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async getSuggestionFulfillVariant(ids: string[], replacement = false) {
    try {
      let result: any = await FulfillOrderServices.getSuggestionFulfillVariant(ids, replacement)
      if(result.data){
        isEmpty(result.data) ? Toasts.warning(t(Labels.fill_suggestion_unsuccessfully)) : Toasts.success(t(Labels.fill_suggestion_successfully))
      }
      return result.data
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async searchByFulfillSku(searchText: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillOrderEntity,
      async () => {
        let filter: Filter<FulfillOrderDto> = {}
        if (searchText) {
          filter.sku = { contains: searchText }
        }
        let pageData = await FulfillOrderServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async searchByOrderNumber(searchText: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillOrderEntity,
      async () => {
        let filter: Filter<FulfillOrderDto> = {}
        if (searchText) {
          filter.orderNumber = { contains: searchText }
        }
        let pageData = await FulfillOrderServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async recreateFulfillProduct(ids: string[] | number[], refresh?: Function) {
    await Confirmations.confirm(
      t(Messages.recreate_fulfill_order_selected),
      async () => {
        let result = await FulfillOrderServices.recreateFulfillProduct(ids)
        if (result?.success) {
          Toasts.success(
            t(
              Messages.process_recreate_fulfill_order_successfully,
              { number: ids.length }
            )
          )
          refresh && refresh()
        } else {
          Toasts.error(t(Messages.your_request_execute_unsuccessfully))
        }
      }
    )
  }


  async createDesignCustom(ids: string[] | number[], refresh: Function) {
    let result = await FulfillOrderServices.createDesignCustom(ids)
    if(result?.success) {
      Toasts.success(t(Messages.your_request_execute_successfully))
      refresh && refresh()
    } else {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
    return result
  }

  async changeVendor (ids: string[] | number[], vendorId: string) {
    try {
      const data = await this.service.bulkAction(FulfillOrderApiPaths.ACTIONS,  ids, OrderActionEnum.CHANGE_VENDOR, { vendorId })
      return data
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
    // return super.runBulkAction(FulfillOrderApiPaths.ACTIONS,  ids, OrderActionEnum.CHANGE_VENDOR, { vendorId })
  }

  async loadUploadDesignOptions(id: string | number) {
    if (uploadDesignCache?.id == id && uploadDesignCache?.options?.length) {
      return uploadDesignCache.options
    }

    return withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillOrderEntity,
      async () => {
        let uploadDesignOptions = await FulfillOrderServices.loadUploadDesignOptions(id)
        const options = uploadDesignOptions?.allDesignNames?.map((design: string) => ({ label: design, value: design })) || []
        if(options?.length) {
          uploadDesignCache = { id, options }
        } else {
          uploadDesignCache = null
        }

        return options
      })
  }

  async bulkActionClone (ids: string[] | number[], amount: number) {
    return super.runBulkAction(FulfillOrderApiPaths.ACTIONS,  ids, OrderActionEnum.CLONE, { amount } as any)
  }

}

let uploadDesignCache: { id: any, options: any[]} | null = null

export const FulfillOrderActions = new FulfillOrderAction()
